<template>
  <div class="screen">
    <ul v-for="item in list" :key="item.title">
      <div>{{ item.title }}</div>
      <li @click="clickRadio(item2.title)" v-for="item2 in item.children" :key="item2.label" :class="value === item2.title ? 'active': ''">{{ item2.label }}</li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String
    },
    value: {
      type: String
    },
    list: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {};
  },
  mounted () {},
  methods: {
    clickRadio (label) {
      this.$emit('input', label);
      this.$emit('change', label);
    }
  }
};
</script>

<style lang="less" scoped>
.screen {
  color: #585858;
  ul {
    display: flex;
    font-size: 14px;
    margin-top: 30px;
    .active {
      color: #156ED0;
    }
    li {
      margin-right: 29px;
      cursor: pointer;
      &:hover {
        color: #156ED0;
      }
    }
    div {
      width: 83px;
      color: #C2C2C2;
    }
  }
}
</style>
